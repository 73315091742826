import React from 'react';
import Draggable from 'react-free-draggable';

const DraggableExample = () => {
    const clickToast = () => {
        console.log('Draggable element clicked!');
    };

    return (
        <div className="battlefield-zone" style={{ display: 'inline-block', width: '500px', height: '200px', background: "red" }}>
            <Draggable bounds=".battlefield-zone" scale={1}>
                <div style={{ display: "inline-block" }}>
                    <div
                        className="handle"
                        style={{ display: "inline-block", width: "80px", background: "blue", cursor: "grab", height: "100%" }}
                        onClick={clickToast}
                    >
                        The Dragging
                    </div>
                </div>
            </Draggable>
        </div>
    );
};

export default DraggableExample;
