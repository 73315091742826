
import React from 'react';
import { useDrop } from 'react-dnd';

function BattlefieldZone({ onDropCard, isOwnZone, zoneName, children}) {
  const [{ isOver }, drop] = useDrop({
    accept: 'CARD',
    canDrop: () => isOwnZone, // Only accept drops if the zone is owned
    drop: (item) => {
      if (isOwnZone) {
        onDropCard(item.cardId, item.fromZone, zoneName);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div
      ref={drop}
      className={`battlefield-zone ${isOver ? 'isOver' : ''}`}
      style={{
        minHeight: '100px',
      }}
    >
      {children}
    </div>
  );
}

export default BattlefieldZone;
