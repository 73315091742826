// src/components/LogMessage.js

import React from 'react';

// eslint-disable-next-line react/display-name
const LogMessage = React.memo(({ message, color }) => {
  console.log('LogMessage color:', color);
  return (
    <div 
      className="log-message" 
      style={{ color }}
    >
      {message}
    </div>
  );
});

export default LogMessage;
