// src/pages/Home.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import '../styles/Home.css';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

function Home() {
  const [showNewRoomModal, setShowNewRoomModal] = useState(false);
  const [showDecksModal, setShowDecksModal] = useState(false);
  const [showNewDeckModal, setShowNewDeckModal] = useState(false);

  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const [rooms, setRooms] = useState([]);
  const [decks, setDecks] = useState([]);

  const [roomName, setRoomName] = useState('');
  const [roomPrivacy, setRoomPrivacy] = useState('Public');

  const [deckName, setDeckName] = useState('');
  const [commanders, setCommanders] = useState('');
  const [deckList, setDeckList] = useState('');
  const [isSaving, setIsSaving] = useState(false); // Add a state to track if saving is in progress
  const [editingDeckId, setEditingDeckId] = useState(null); // State for the deck being edited

  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated && user) {
      console.log("Logged in as: ", user.email);  // Ensure this shows the correct email
      axios.post(`${backendUrl}/api/users/find-or-create`, { email: user.email, name: user.name, auth0Id: user.sub })
        .then(response => {
          console.log('User verified or created:', response.data);
        })
        .catch(error => {
          console.error('Error verifying/creating user:', error);
        });
    }
  }, [isAuthenticated, user]);

  // Fetch rooms from the backend
  useEffect(() => {
    axios.get(`${backendUrl}/api/rooms`)
      .then((response) => setRooms(response.data))
      .catch((error) => console.error('Error fetching rooms:', error));
  }, []);

  useEffect(() => {
    if (isAuthenticated && user) {
      const email = user.email;  // Should be Hotmail or Gmail based on the logged-in user
      axios
        .get(`${backendUrl}/api/decks/${email}`)
        .then((response) => {
          setDecks(response.data);
        })
        .catch((error) => {
          console.error('Error fetching decks:', error);
        });
    }
  }, [isAuthenticated, user]);  

  useEffect(() => {
    const fetchRooms = () => {
      axios
        .get(`${backendUrl}/api/rooms`)
        .then((response) => setRooms(response.data))
        .catch((error) => console.error('Error fetching rooms:', error));
    };
  
    fetchRooms(); // Initial fetch
  
    const intervalId = setInterval(fetchRooms, 300000); // Refresh every 5 minutes
  
    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);  

  const handleCreateRoom = () => {
    if (!user) return;
  
    const username = user['https://replacementeffect.com/username'];
  
    const newRoom = {
      name: roomName,
      privacy: roomPrivacy,
      hostId: user.sub, // Using Auth0 user ID as the host identifier
      hostUsername: username, // Pass the username
      hostEmail: user.email,
    };
  
    console.log('Creating room with data:', newRoom); // Log the data being sent
  
    axios
      .post(`${backendUrl}/api/rooms`, newRoom)
      .then((response) => {
        setRooms([...rooms, response.data]);
        setShowNewRoomModal(false);
        setRoomName('');
        setRoomPrivacy('Public');
      })
      .catch((error) => {
        console.error('Error creating room:', error); // Log the actual error response
        if (error.response && error.response.status === 400) {
          alert(error.response.data.error);
        } else {
          alert('An error occurred while creating the room.');
        }
      });
  };
  
  const handleCreateDeck = () => {
    if (isSaving) return; // Prevent function from executing if already saving
    
    setIsSaving(true); // Set to true when saving starts
    const email = user.email;
  
    // Parse the deckList textarea input into an array of card objects
    const parsedDeckList = deckList.split('\n').map(line => {
      const [quantity, ...nameParts] = line.trim().split(' ');
      return { cardName: nameParts.join(' '), quantity: parseInt(quantity) };
    });
  
    const deckData = {
      name: deckName,
      commanders: commanders,
      deckList: parsedDeckList, // Array of card objects including cardName
    };
  
    if (editingDeckId) {
      // If editingDeckId is set, update the existing deck
      axios
        .put(`${backendUrl}/api/decks/${editingDeckId}`, deckData)
        .then((response) => {
          setDecks(decks.map(deck => deck._id === editingDeckId ? response.data : deck)); // Update the deck in the local state
          setShowNewDeckModal(false);
          setEditingDeckId(null); // Clear editing state after save
        })
        .catch((error) => {
          console.error('Error updating deck:', error);
        })
        .finally(() => {
          setIsSaving(false); // Re-enable the button after save is done
        });
    } else {
      // If no editingDeckId, create a new deck
      axios
        .post(`${backendUrl}/api/decks`, { email, ...deckData })
        .then((response) => {
          setDecks([...decks, response.data]); // Add new deck to the list
          setShowNewDeckModal(false);
        })
        .catch((error) => {
          console.error('Error creating deck:', error);
        })
        .finally(() => {
          setIsSaving(false); // Re-enable the button after save is done
        });
    }
  };  

  const handleEditDeck = (deck) => {
    setEditingDeckId(deck._id); // Track the deck being edited
    setDeckName(deck.name);
    setCommanders(deck.commanders.map(commander => commander.cardName).join(', ')); // Prefill the commander name
    setDeckList(deck.cards.map(card => `${card.quantity} ${card.cardName}`).join('\n')); // Map cardName and quantity to the deckList textarea
    setShowNewDeckModal(true); // Show the modal
  };
  
  
  const handleDeleteDeck = (deckId) => {
    axios
      .delete(`${backendUrl}/api/decks/${deckId}`)
      .then(() => {
        setDecks(decks.filter(deck => deck._id !== deckId)); // Remove the deck from local state
        if (editingDeckId === deckId) {
          setEditingDeckId(null); // Clear editing state if the deleted deck was being edited
        }
      })
      .catch((error) => {
        console.error('Error deleting deck:', error);
      });
  };  

  const handleJoinRoom = async (roomId) => {
    if (!isAuthenticated) {
      loginWithRedirect();
      return;
    }

    try {
      const response = await axios.get(`${backendUrl}/api/rooms/${roomId}`);
      const room = response.data;

      // Check if the user is in the kickedUsers array
      if (room.kickedUsers.includes(user.email)) {
        alert('You have been kicked from this room and cannot rejoin.');
        return;
      }

      // Check if room is full (max 5 players)
      if (room.occupants.length >= 5) {
        alert('This room is full.');
        return;
      }

      // Add user to room's occupants
      try {
        await axios.post(`${backendUrl}/api/rooms/${roomId}/occupants`, { userId: user.sub });
        // Navigate to the room if successfully added
        navigate(`/room/${roomId}`);
      } catch (error) {
        if (error.response && error.response.status === 400) {
          // User is already in room
          navigate(`/room/${roomId}`);
        } else {
          console.error('Error joining room:', error);
          alert('Failed to join room. Please try again later.');
        }
      }

    } catch (error) {
      console.error('Error fetching room details:', error);
      alert('Failed to join room. Please try again later.');
    }
  };

  const handleDeleteRoom = (roomId, host) => {
    // Only allow deletion if the current user is the host
    if (user.sub !== host) {
      alert('Only the host can delete the room');
      return;
    }

    axios
      .delete(`${backendUrl}/api/rooms/${roomId}`)
      .then(() => {
        // Remove the room from local state
        setRooms(rooms.filter(room => room._id !== roomId));
      })
      .catch((error) => {
        console.error('Error deleting room:', error);
        alert('Failed to delete room');
      });
  };

  return (
    <Container fluid>
      <Row>
        {/* Side Navigation */}
        <Col xs={12} md={3} className="sidebar">
            <img 
                src="https://i.imgur.com/kxncKD4.png" 
                alt="Logo" 
                className="nav-logo"
            />
          <div className="d-flex flex-column align-items-start px-3">
            <Button
              variant=""
              className="nav-button button-newRoom mb-3"
              onClick={() => {
                console.log('New Room button clicked');
                if (isAuthenticated) {
                  setShowNewRoomModal(true);
                  console.log('showNewRoomModal changed:', showNewRoomModal);
                } else {
                  loginWithRedirect();
                }
              }}
            >
              New Room
            </Button>
            <Button
              variant=""
              className="nav-button button-decks mb-3"
              onClick={() => { 
                console.log('Decks button clicked');
                if (isAuthenticated) {
                  setShowDecksModal(true);
                  console.log('showDecksModal changed:', showNewRoomModal);
                } else {
                  loginWithRedirect();
                }
              }}
            >
              Decks
            </Button>
            <Button
              variant=""
              className="nav-button button-login mb-3"
              onClick={() => {

                if (isAuthenticated) {
                  logout({ returnTo: window.location.origin });
                } else {
                  loginWithRedirect();
                }
              }}
            >
              {isAuthenticated ? 'Log Out' : 'Log In'}
            </Button>
          </div>
        </Col>

        {/* Main Content Area */}
        <Col xs={12} md={9} className="main-content">
        <div className="title-container">
            <img
                src="https://i.imgur.com/wMK3yxX.png"
                alt="Replacement Effect"
                className="site-logo" // Optional: Add a class for styling if needed
            />
            <br></br>
            <h4 className="rooms-title">{isAuthenticated && user && (
                <p>Welcome, {user['https://replacementeffect.com/username']}</p>
            )}</h4>
            <br></br>
            <h4 className="rooms-title">Join or Create a Room to Play Some Commander!</h4>
        </div>
        <div className="rooms-list mt-4">
            {rooms.map((room) => (
            <div key={room.id} className="room-item">
                <div className="room-details">
                <span className="room-host">{room.hostUsername}</span>
                <span className="room-occupants">{room.occupants.length} / 4</span>
                <span className="room-name">{room.name}</span>
                <span className="room-privacy">{room.privacy}</span>
                <Button
                    className="join-button"
                    onClick={() => handleJoinRoom(room._id)}
                >
                    Join Room
                </Button>
                {user && user.sub === room.host && (
                  <Button
                    className="btn delete-button"
                    onClick={() => handleDeleteRoom(room._id, room.host)}
                  >
                    Delete Room
                  </Button>
                )}
                </div>
            </div>
            ))}
        </div>
        </Col>
      </Row>

      {/* New Room Modal */}
      <Modal
        show={showNewRoomModal}
        onHide={() => setShowNewRoomModal(false)}
        centered
        className="new-room-modal"
        dialogClassName='new-room-modal'
        style={{ width: '100% !important' }} // Set the modal width to 100%
      >
        <Modal.Header>
          <Modal.Title>Create New Room</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Group controlId="roomName">
                    <Form.Label>Room Name</Form.Label>
                    <Form.Control
                    type="text"
                    placeholder="Enter room name"
                    value={roomName}
                    maxLength={34} // Limits input to 34 characters
                    onChange={(e) => setRoomName(e.target.value)}
                    required
                    />
                </Form.Group>

                <Form.Group controlId="roomPrivacy">
                    <Form.Label>Privacy</Form.Label>
                    <Form.Control
                    as="select"
                    value={roomPrivacy}
                    onChange={(e) => setRoomPrivacy(e.target.value)}
                    required
                    >
                    <option>Public</option>
                    <option>Private</option>
                    </Form.Control>
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowNewRoomModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateRoom}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Decks Modal */}
      <Modal
        show={showDecksModal}
        onHide={() => setShowDecksModal(false)}
        style={{ opacity: showNewDeckModal ? 0 : 1, width: 'auto !important' }}
        centered
      >
      <Modal.Header closeButton>
        <Modal.Title>Your Decks</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="deck-list">
          {/* List of decks */}
          {decks.map((deck) => (
            <div key={deck.id} className="deck-item">
              <div className="deck-details">
                <h5>{deck.name}</h5>
                <div className="button-group">
                  <Button 
                    variant="" 
                    onClick={() => handleEditDeck(deck)}
                  >
                    Edit
                  </Button>
                  <Button 
                    variant="" 
                    onClick={() => handleDeleteDeck(deck._id)}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="" className="add-new-deck-btn" onClick={() => {
          setDeckName('');
          setCommanders('');
          setDeckList('');
          setEditingDeckId(null); // Reset editing state
          setShowNewDeckModal(true);
        }}>
          Add New Deck
        </Button>
      </Modal.Footer>
    </Modal>
    {/* New Deck Modal */}
    <Modal show={showNewDeckModal} onHide={() => setShowNewDeckModal(false)} centered>
    <Modal.Header closeButton>
        <Modal.Title>{`${editingDeckId ? 'Edit Deck' : 'Create New Deck'}`}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <Form>
        <Form.Group controlId="deckName">
            <Form.Label>Deck Name</Form.Label>
            <Form.Control
            type="text"
            placeholder="Enter deck name"
            value={deckName}
            onChange={(e) => setDeckName(e.target.value)}
            maxLength={25} // Limits input to 25 characters
            required
            />
        <Form.Text muted>{`${deckName.length}/25 characters`}</Form.Text> {/* Shows character count */}
        </Form.Group>
        <br></br>

        <Form.Group controlId="commander">
            <Form.Label>Commander(s)</Form.Label>
            <br></br>
            <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter commander(s) card name"
            value={commanders}
            onChange={(e) => setCommanders(e.target.value)}
            required
            />
        </Form.Group>

        <Form.Group controlId="deckList">
            <br></br>
            <Form.Label>Deck List <br></br>(*Don't include your commander here. Only in the commander section.*)</Form.Label>
            <Form.Control
            as="textarea"
            rows={5}
            placeholder={`Example:\n1 Coveted Falcon\n1 Dingus Egg\n1 Armageddon\n1 Lumra, Bellow of the Woods`}            
            value={deckList}
            onChange={(e) => setDeckList(e.target.value)}
            required
            />
        </Form.Group>
        </Form>
    </Modal.Body>
    <Modal.Footer>
        <Button variant="" className="close-btn" onClick={() => setShowNewDeckModal(false)}>
        Close
        </Button>
        <Button 
        variant="primary" 
        onClick={handleCreateDeck} 
        disabled={isSaving} // Disable button while saving
        >
        {isSaving ? 'Saving...' : 'Save Deck'}
        </Button>
    </Modal.Footer>
    </Modal>

    </Container>
  );
}

export default Home;
