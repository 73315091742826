// Card.js

import React, { useRef } from 'react';
import { useDrag } from 'react-dnd';

// eslint-disable-next-line react/display-name
const Card = React.forwardRef(
  (
    {
      cardId,
      uniqueId,
      cardImage,
      fromZone,
      onCardAction,
      isOwnCard,
      isOnBattlefield,
      isDragging,
      isTapped,
      isFlipped,
      onShowPreview,
      onHidePreview,
      counters,
      ...props
    },
    ref
  ) => {
    const previewTimeoutRef = useRef(null);

    const [, drag] = useDrag({
      type: 'CARD',
      item: { cardId: uniqueId, fromZone },
      canDrag: !isOnBattlefield && isOwnCard,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    });

    const combinedRef = React.useCallback(
      (node) => {
        if (node && !isOnBattlefield && isOwnCard) {
          drag(node);
        }
        if (ref) {
          if (typeof ref === 'function') {
            ref(node);
          } else {
            ref.current = node;
          }
        }
      },
      [drag, ref, isOwnCard, isOnBattlefield]
    );

    const handleMouseEnter = (e) => {
      if (!onShowPreview) {
        return;
      }
      if (fromZone !== 'hand') {
        if (previewTimeoutRef.current) {
          clearTimeout(previewTimeoutRef.current);
        }

        previewTimeoutRef.current = setTimeout(() => {
          const screenWidth = window.innerWidth;
          const mouseX = e.clientX;
          const isRightSide =
            fromZone === 'commandZone' || fromZone === 'exile' || fromZone === 'graveyard'
              ? 'center'
              : mouseX > screenWidth / 2;
          onShowPreview(cardImage, isRightSide);
        }, 500);
      }
    };

    const handleMouseLeave = () => {
      if (!onHidePreview) {
        return;
      }
      if (previewTimeoutRef.current) {
        clearTimeout(previewTimeoutRef.current);
      }
      onHidePreview();
    };

    React.useEffect(() => {
      return () => {
        if (previewTimeoutRef.current) {
          clearTimeout(previewTimeoutRef.current);
        }
      };
    }, []);

    return (
      <img
        ref={combinedRef}
        src={cardImage}
        alt={`Card ${cardId}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...props}
        style={{
          cursor: isOwnCard ? 'grab' : 'default',
          pointerEvents: isDragging ? 'none' : 'auto',
          border: 'none',
          backgroundColor: 'transparent',
          transform: isTapped ? 'rotate(90deg)' : 'none',
          transition: 'transform 0.2s ease-in-out',
        }}
        className={isTapped ? 'tapped' : ''}
      />
    );
  }
);

export default Card;
