// src/components/Zone.js

import React from 'react';
import { useDrop } from 'react-dnd';

function Zone({ zoneName, onDropCard, isOwnZone, onZoneClick, children }) {
  const [{ isOver }, drop] = useDrop({
    accept: 'CARD',
    canDrop: () => isOwnZone, // Only accept drops if the zone is owned
    drop: (item) => {
      if (isOwnZone) {
        onDropCard(item.cardId, item.fromZone, zoneName);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const handleClick = (e) => {
    // Prevent the click event from propagating to the drop area
    e.stopPropagation();
    if (onZoneClick) {
      onZoneClick();
    }
  };

  return (
    <div
      ref={drop}
      className={`zone ${isOver ? 'isOver' : ''} ${zoneName}`}
      style={{
        minHeight: '100px',
        cursor: 'pointer',
      }}
    >
      <div onClick={handleClick} style={{ height: '100%', width: '100%' }}>
        {children}
      </div>
    </div>
  );
}

export default Zone;
